import React, { MouseEventHandler, useState } from 'react';

import {
  Close,
  DownloadBottom as DownloadIcon,
} from '@videoblocks/react-icons';

import { promoOfferContexts } from '../../../../common/Constants';
import Popper, {
  Placements,
} from '../../../../common/components/Popper/Popper';

type Props = {
  detailsUrl?: string;
  onPrimaryActionClicked: MouseEventHandler<HTMLAnchorElement>;
  onSecondaryActionClicked: MouseEventHandler<HTMLAnchorElement>;
  redirectToSignUpLink?: boolean;
  showCancelLabel: boolean;
};

const StockItemCardButtons = ({
  detailsUrl = '',
  onPrimaryActionClicked,
  onSecondaryActionClicked,
  redirectToSignUpLink = false,
  showCancelLabel,
}: Props) => {
  const [showDownloadTooltip, setShowDownloadTooltip] = useState(false);
  const [showCancelTooltip, setShowCancelTooltip] = useState(false);

  const formLink = () => {
    return redirectToSignUpLink ? '/sign-up' : detailsUrl;
  };

  return (
    <div className="absolute z-50 block w-full h-full p-0 opacity-100">
      <Popper
        showContent={showDownloadTooltip}
        placement={Placements.Top}
        popperClasses="bg-white rounded"
        triggerElement={(referenceElement) => (
          <a
            className="download-trigger hover:text-black focus:text-black hover:bg-yellow-500 focus::bg-yellow-500 inline-flex items-center justify-center w-1/2 h-full p-0 m-0 text-center text-white align-middle bg-black border-none cursor-pointer"
            data-cy="download-trigger"
            data-promo-context={promoOfferContexts.cardDownload}
            href={formLink()}
            onClick={onPrimaryActionClicked}
            onMouseEnter={() => setShowDownloadTooltip(true)}
            onMouseLeave={() => setShowDownloadTooltip(false)}
            ref={referenceElement}
          >
            <DownloadIcon
              className="download-icon fill-current w-3.5 h-3.5"
              color="null"
            />
          </a>
        )}
      >
        <div id="tooltip-download" className="p-1 text-xs">
          Download
        </div>
      </Popper>

      <Popper
        showContent={showCancelTooltip}
        placement={Placements.Top}
        popperClasses="bg-white rounded"
        triggerElement={(referenceElement) => (
          <a
            className="hover:text-black focus:text-black hover:bg-yellow-500 focus:bg-yellow-500 hover:no-underline focus:no-underline inline-flex items-center justify-center w-1/2 h-full p-0 m-0 text-center text-white align-middle bg-black border-none cursor-pointer"
            data-promo-context={promoOfferContexts.cardDownload}
            href={formLink()}
            onClick={onSecondaryActionClicked}
            onMouseEnter={() => setShowCancelTooltip(true)}
            onMouseLeave={() => setShowCancelTooltip(false)}
            ref={referenceElement}
          >
            <Close
              className="cancal-icon fill-current w-3.5 h-3.5"
              color="null"
            />
          </a>
        )}
      >
        <div id="tooltip-download" className="p-1 text-xs">
          {showCancelLabel ? 'Cancel' : 'Add to Folder'}
        </div>
      </Popper>
    </div>
  );
};

export default StockItemCardButtons;
