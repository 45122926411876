import StockItemFormatSelector, {
  StockItemFormatSelectorProps,
} from './StockItemFormatSelector';

interface Props extends StockItemFormatSelectorProps {
  downloadPermissions?: Record<string, boolean>;
}

class VideoStockItemFormatSelector extends StockItemFormatSelector<Props> {
  componentDidMount() {
    const noFourKDownloadPermission =
      this.props.downloadPermissions &&
      this.props.downloadPermissions.fourK === false;
    const isFourK = this.props.stockItemFormats[0]['quality'] === '4K';
    if (noFourKDownloadPermission || isFourK) {
      this.selectHDOption();
    }
  }

  selectHDOption() {
    const firstHDOption = this.props.stockItemFormats.find(
      (format) => format.quality === 'HD'
    );

    if (firstHDOption) {
      this.props.selectedFormatChanged(
        firstHDOption.formatName,
        firstHDOption.resolution
      );
    }
  }
}

export default VideoStockItemFormatSelector;
