import React from 'react';

import { NewTag } from '@videoblocks/storywind';

import './StockItemIcons.less';

interface Props {
  showNewFlag?: boolean;
}

/**
 * This is used to display visual badges on a StockItem card.
 * .StockItemIcons class has pointer-events: none and will
 * pass clicks through to the stock item image, which
 * will lead users to the details URL.
 */
export default function StockItemIcons(props: Props) {
  const { showNewFlag } = props;

  if (!showNewFlag) return null;

  return (
    <div className="StockItemIcons absolute flex items-center z-20">
      <NewTag className="text-black bg-white" />
    </div>
  );
}

StockItemIcons.defaultProps = {
  showNewFlag: false,
};
