import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DownloadBottom as DownloadIcon } from '@videoblocks/react-icons';

import { deleteFolderContent } from '../../../../../Member/MemberBin/FolderActions';
import { selectMemberBins } from '../../../../../Member/MemberBins/selectors/foldersSelectors';
import {
  selectHasVideoSubscription,
  selectIsLoggedIn,
} from '../../../../../auth/AuthSelectors';
import { default as FavoriteButtonIcon } from '../../../../../common/components/FavoriteButton';
import SignUpForMakerPopover from '../../../../../common/components/Popover/SignUpForMakerPopover';
import Popper, {
  Placements,
} from '../../../../../common/components/Popper/Popper';
import {
  convertStockItemContextToOriginArea,
  isStockItemInAnyFolder,
} from '../../../../../common/utils';
import { DrawerType } from '../../../SearchTypes';
import {
  selectDrawerIsOpen,
  selectDrawerSelectedItemId,
  selectDrawerType,
} from '../../../selectors/searchSelectors';
import RecommendedMusicIcon from '../RecommendedMusicIcon';
import SimilarVideosIcon from '../SimilarVideosIcon';
import { VideoStockItemCardProps } from '../VideoStockItemCard';

// all buttons extracted from VideoStockItemCard
export const DownloadButton = (
  props: Pick<
    VideoStockItemCardProps,
    'shouldShowDownloadButton' | 'downloadButtonTelemetryAction'
  > & {
    onClick: (evt: React.SyntheticEvent) => void;
  }
) => {
  const { shouldShowDownloadButton, downloadButtonTelemetryAction } = props;
  const [showTooltip, setShowTooltip] = React.useState(false);
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const videoSubscription = useSelector(selectHasVideoSubscription);

  const isASubscriptionlessUser = isLoggedIn && !videoSubscription;

  if (shouldShowDownloadButton === false) return null;

  const fireDownloadTelemetryActionFromParent = () => {
    downloadButtonTelemetryAction?.();
  };

  return (
    <div>
      <Popper
        placement={Placements.Left}
        popperClasses="px-2 py-1 text-white bg-black rounded opacity-90 text-sm"
        showContent={showTooltip}
        triggerElement={(referenceElement) => (
          <div
            tabIndex={0}
            className="action-wrapper download-button action-download button-sizing text-white"
            data-cy="download-button"
            ref={referenceElement}
            onClick={props.onClick}
            onKeyDown={props.onClick}
            onMouseEnter={() => setShowTooltip(true)}
            onFocus={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
            onBlur={() => setShowTooltip(false)}
            aria-label="download"
            role="button"
          >
            <DownloadIcon
              onClick={fireDownloadTelemetryActionFromParent}
              className="action-icon fill-current"
            />
          </div>
        )}
      >
        <div id="tooltip-download">
          {isASubscriptionlessUser ? 'Download Preview' : 'Download'}
        </div>
      </Popper>
    </div>
  );
};

export const FavoriteButton = (
  props: Pick<
    VideoStockItemCardProps,
    | 'stockItem'
    | 'stockItemFormats'
    | 'favoriteButtonTelemetryAction'
    | 'containPoppers'
    | 'openFoldersInNewWindow'
    | 'context'
  > & { onClick: (e: MouseEvent) => void }
) => {
  const memberBins = useSelector(selectMemberBins);
  const bins = memberBins ? Object.values(memberBins) : [];
  const [showTooltip, setShowTooltip] = React.useState(false);

  const tooltipText = isStockItemInAnyFolder(bins, props.stockItem)
    ? 'Added to Folder'
    : 'Add to Folder';

  const fireFavoriteButtonTelemetryFromParent = () => {
    props.favoriteButtonTelemetryAction?.();
  };

  const originArea = convertStockItemContextToOriginArea(props.context);

  return (
    <div>
      <Popper
        placement={Placements.Left}
        popperClasses="px-2 py-1 text-white bg-black rounded opacity-90 text-sm"
        showContent={showTooltip}
        triggerElement={(referenceElement) => (
          <div
            className="action-wrapper button-sizing"
            ref={referenceElement}
            onMouseEnter={() => setShowTooltip(true)}
            onFocus={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
            onBlur={() => setShowTooltip(false)}
          >
            <FavoriteButtonIcon
              onButtonClick={() => {
                fireFavoriteButtonTelemetryFromParent();
                setShowTooltip(false);
              }}
              onClickAction={props.onClick}
              stockItem={props.stockItem}
              stockItemFormats={props.stockItemFormats}
              telemetryKey="search"
              iconClassName="p-1.5 w-5 h-5"
              containPoppers={props.containPoppers}
              openFoldersInNewWindow={props.openFoldersInNewWindow}
              originArea={originArea}
            />
          </div>
        )}
      >
        <div id="tooltip-favorite" className="whitespace-nowrap">
          {tooltipText}
        </div>
      </Popper>
    </div>
  );
};

export const DeleteButton = (
  props: Pick<
    VideoStockItemCardProps,
    'shouldShowDeleteButton' | 'binUniqueId' | 'stockItem'
  >
) => {
  const [showTooltip, setShowTooltip] = React.useState(false);
  const dispatch = useDispatch();

  if (props.shouldShowDeleteButton === false) return null;

  const deleteFromFolder = () => {
    // @ts-ignore
    dispatch(deleteFolderContent(props.binUniqueId, [props.stockItem]));
  };

  return (
    <div>
      <Popper
        placement={Placements.Left}
        popperClasses="px-2 py-1 text-white bg-black rounded opacity-90 text-sm"
        showContent={showTooltip}
        triggerElement={(referenceElement) => (
          <div
            tabIndex={0}
            className="action-wrapper download-button action-download button-sizing"
            ref={referenceElement}
            onMouseEnter={() => setShowTooltip(true)}
            onFocus={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
            onBlur={() => setShowTooltip(false)}
            aria-label="delete from folder"
            role="button"
          >
            <div className="action-wrapper">
              <img
                src={`${__ASSETS_COMMON_IMAGES_URL__}/member/folders/trash-with-padding.svg`}
                alt="Delete from folder icon"
                className="stockItemCard-deleteButton action-icon"
                onClick={deleteFromFolder}
                onKeyDown={deleteFromFolder}
              />
            </div>
          </div>
        )}
      >
        <div id="tooltip-delete">Delete from Folder</div>
      </Popper>
    </div>
  );
};

export const MakerButton = (
  props: Pick<
    VideoStockItemCardProps,
    'stockItem' | 'makerButtonTelemetryAction'
  >
) => (
  <SignUpForMakerPopover
    mediaType="video"
    iconClassName="fill-current text-white"
    stockItemId={props.stockItem.id}
    onClickAction={() => props.makerButtonTelemetryAction?.()}
  />
);

export const SimilarButton = (
  props: Pick<VideoStockItemCardProps, 'stockItem'> & { onClick: () => void }
) => {
  const drawerIsOpen = useSelector(selectDrawerIsOpen);
  const drawerSelectedItemId = useSelector(selectDrawerSelectedItemId);
  const drawerType = useSelector(selectDrawerType);
  const [showTooltip, setShowTooltip] = React.useState(false);

  const isSimilarVideosDrawerOpen = () =>
    [
      drawerSelectedItemId === props.stockItem.id,
      drawerType === DrawerType.MORE_LIKE_THIS,
      drawerIsOpen,
    ].every(Boolean);

  return (
    <div className="max-w-11.5">
      <Popper
        placement={Placements.Top}
        popperClasses="px-2 py-1 text-white bg-black rounded opacity-90 text-sm"
        showContent={showTooltip}
        triggerElement={(referenceElement) => (
          <div
            ref={referenceElement}
            onMouseEnter={() => setShowTooltip(true)}
            onFocus={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
            onBlur={() => setShowTooltip(false)}
            role="button"
            aria-label="More like this"
            tabIndex={0}
          >
            <div
              className="action-wrapper similar-button"
              role="button"
              tabIndex={0}
              onClick={props.onClick}
              onKeyDown={props.onClick}
            >
              <SimilarVideosIcon
                className="action-icon p-1"
                width={38}
                height={20}
                state={isSimilarVideosDrawerOpen() ? 'opened' : 'closed'}
              />
            </div>
          </div>
        )}
      >
        <div className="whitespace-nowrap" id="tooltip-similar">
          More Like This
        </div>
      </Popper>
    </div>
  );
};

export const RecommendedMusicButton = (
  props: Pick<VideoStockItemCardProps, 'stockItem'> & { onClick: () => void }
) => {
  const drawerIsOpen = useSelector(selectDrawerIsOpen);
  const drawerSelectedItemId = useSelector(selectDrawerSelectedItemId);
  const drawerType = useSelector(selectDrawerType);
  const [showTooltip, setShowTooltip] = React.useState(false);

  const isRecommendedMusicrawerOpen = () =>
    [
      drawerSelectedItemId === props.stockItem.id,
      drawerType === DrawerType.RECOMMENDED_MUSIC,
      drawerIsOpen,
    ].every(Boolean);

  return (
    <div className="max-w-11.5">
      <Popper
        placement={Placements.Top}
        popperClasses="px-2 py-1 text-white bg-black rounded opacity-90 text-sm"
        showContent={showTooltip}
        triggerElement={(referenceElement) => (
          <div
            tabIndex={0}
            ref={referenceElement}
            onMouseEnter={() => setShowTooltip(true)}
            onFocus={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
            onBlur={() => setShowTooltip(false)}
            aria-label="Recommended Music"
            role="button"
          >
            <div
              role="button"
              tabIndex={0}
              className="action-wrapper recommended-music-button"
              onClick={props.onClick}
              onKeyDown={props.onClick}
            >
              <RecommendedMusicIcon
                className="action-icon p-1"
                width={38}
                height={20}
                state={isRecommendedMusicrawerOpen() ? 'opened' : 'closed'}
              />
            </div>
          </div>
        )}
      >
        <div id="tooltip-similar" className="whitespace-nowrap">
          Recommended Music
        </div>
      </Popper>
    </div>
  );
};
